


































































































import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import TableFooter from '@/components/TableFooter.vue'
import CaptionCard from '@/components/cards/CaptionCard.vue'
import FiltersPanel from '@/components/FiltersPanel.vue'

// INTERFACES
import { CarParkRepairResource, CountResource } from '@/store/types'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

// STORE
import RepairModule from '@/store/modules/fleet/repair'

// LIBRARIES
import { Bind, Debounce } from 'lodash-decorators'
import { isEqual } from 'lodash'
import { DateTime } from 'luxon'
import DataTable from '@/components/tables/components/DataTable.vue'

@Component({
  components: {
    DataTable,
    TableFooter,
    CaptionCard,
    FiltersPanel,
  },
})
export default class RepairTable extends Mixins(SystemMixin, NotifyMixin) {
  private get tableData (): CarParkRepairResource[] {
    return RepairModule.tableData.data.map(item => ({
      ...item,
      dateOfActOfTransfer: DateTime.fromFormat(`${item.dateOfActOfTransfer}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
      dateOfActOfReturn: DateTime.fromFormat(`${item.dateOfActOfReturn}`, 'yyyy-MM-dd').toFormat('dd.MM.yy'),
    }))
  }

  private get headers (): any {
    return RepairModule.tableHeaders.filter(item => item.visible)
  }

  private get dictionary (): CountResource[] {
    return RepairModule.statusDictionary
  }

  private get filter (): any {
    return RepairModule.filters
  }

  private set filter (value: any) {
    if (!isEqual(value, this.filter) || this.tableData.length === 0) {
      RepairModule.setFilters(value)
      this.fetchTableData()
    }
  }

  private get pagination () {
    return RepairModule.tableData.meta
  }

  private get activeStatus (): string | undefined {
    return RepairModule.filters.status
  }

  private set activeStatus (value: string | undefined) {
    RepairModule.setActiveStatus(value)
    RepairModule.getTableData(+this.entity)
  }

  private goToCarInfo (item: any){
    this.$router.push({ name: 'car-info', params: { carId: item.id } })
  }

  private isReady = false

  private created () {
    RepairModule.resetFilters()

    RepairModule.syncTableHeaders()
    RepairModule.getFiltersDictionary(+this.entity)
    this.fetchTableData()

    this.$bus.$on('changeRepairFilters', this.fetchTableData.bind(this))
  }

  private changeSortBy (sortBy: string | string[]) {
    RepairModule.setFilters({ ...this.filter, sortBy })
    this.fetchTableData()
  }

  private changeSortDesc (sortDesc: boolean | boolean[]) {
    RepairModule.setFilters({ ...this.filter, sortDesc })
    this.fetchTableData()
  }

  private copyText (text: string) {
    navigator.clipboard.writeText(text.trim())
  }

  private getColor (status: string) {
    switch (status.toLowerCase()) {
      case 'согласован': return 'green'
      case 'оспаривается' : return 'orange'
      case 'новое' : return 'blue'
      default: return 'blue'
    }
  }

  private toApprove (item: any) {
    this.$openModal(
      'RepairToApproveModal',
      'small',
      {
        id: item.inspectionReportId,
        vin: item.vin,
        total: item.costAll.replace(/,.*|\s/g, ''),
        detail: item.costReinvoiceservice.replace(/,.*|\s/g, ''),
        entity: +this.entity,
      },
    )
  }

  private toContest (item: any) {
    this.$openModal(
      'RepairToContestModal',
      'small',
      {
        id: item.inspectionReportId,
        vin: item.vin,
        total: item.costAll.replace(/,.*|\s/g, ''),
        detail: item.costReinvoiceservice.replace(/,.*|\s/g, ''),
        entity: +this.entity,
      },
    )
  }

  @Bind
  @Debounce(150)
  private fetchTableData () {
    RepairModule.getStatusDictionary(+this.entity)
    RepairModule.getTableData(+this.entity)
      .then(() => {
        this.isReady = true
        RepairModule.getStatusDictionary(+this.entity)
      })
  }

  private beforeDestroy () {
    RepairModule.resetFilters()
  }
}
